body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#gov{
  background: #f4f5f9;
  border: 2px solid #f4f5f9;
  border-radius: 50px;
  height: 45px;
  box-shadow: none;
  padding-left: 20px;
  font-size: 13px;
  color: #4f5d77;
  width: 100%;
}
.incDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 10%;
  margin: 2px;
  color: #fff;
  background-color: #fff;
  cursor: pointer;
  font-size: 20px;
  background: #048a26;
}

.decDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 10%;
  margin: 2px;
  color: #fff;
  background-color: #fff;
  cursor: pointer;
  font-size: 20px;
  background: #dc3545;
}
.count{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 10%;
  margin: 2px;
  color: #008dde;
  background-color: #eeeeeef0;
  /* border: 3px solid #008dde; */
  cursor: pointer;
  font-size: 21px;
  font-weight: bold;
}
